<template>
  <div>
    <div v-if="error">
      <p class="error">
        {{ error }}
      </p>
    </div>
    <div
      v-else-if="userNFTs && userNFTs.length == 0"
      class="show-connect-overlay"
      @click.self="closeConnectComponent"
    >
      <connectNFT
        subtitle="Please connect your wallet to verify your Poolsuite Executive Club membership."
        @walletConnected="walletConnected"
        @closeConnectComponent="closeConnectComponent"
      />
    </div>
    <div v-else>
      <div class="loading inset-0 flex items-center justify-center absolute">
        <div class="bg-white rounded-lg p-4">
          <svg
            class="animate-spin h-5 w-5 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/firestore";

import NFTStore from "@poolside-fm/nft-store";
import connectNFT from "@poolside-fm/connect-component";

import store from "./store";
Vue.use(NFTStore, { store: store, firebase: firebase });

export default {
  name: "App",
  components: { connectNFT },
  data() {
    return {
      STATE: NFTStore.STATE,
      error: null,
      loading: false,
    };
  },
  computed: {
    primaryNFTType() {
      return this.$store.getters["nft/primaryNFTType"];
    },
    userNFTs() {
      return this.$store.getters["nft/userNFTs"];
    },
    userAddress() {
      return this.$store.getters["nft/userAddress"];
    },
    connectionState() {
      return this.$store.getters["nft/connectionState"];
    },
  },
  watch: {
    connectionState() {
      window.parent.postMessage(
        {
          type: "connectionState",
          state: this.connectionState,
        },
        "https://www.vacation.inc"
      );
    },
  },
  mounted() {
    const config = this.$store.getters.firestoreConfig(process.env.NODE_ENV);
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
      // UNCOMMENT FOR FIREBASE EMULATOR USE
      //firebase.functions().useEmulator("localhost", 5001);
    }
    this.$store.dispatch("nft/start").then(() => {
      // Store is ready to roll
    });
  },
  methods: {
    closeConnectComponent() {
      window.parent.postMessage(
        "closeConnectComponent",
        "https://www.vacation.inc"
      );
    },
    walletConnected() {
      this.loading = true;
      if (!this.userNFTs || this.userNFTs.length === 0) {
        this.error =
          "You must be a Poolsuite Executive Member to buy this product.";
        return;
      }
      window.parent.postMessage(
        {
          type: "userAddress",
          state: this.userAddress,
        },
        "https://www.vacation.inc"
      );
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Editorial New";
  src: url("./assets/nft-assets/fonts/EditorialNew-Regular.woff2")
      format("woff2"),
    url("./assets/nft-assets/fonts/EditorialNew-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Chicago";
  src: url("./assets/nft-assets/fonts/chikarego2-webfont.woff2") format("woff2"),
    url("./assets/nft-assets/fonts/chikarego2-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Arial";
  src: url("./assets/nft-assets/fonts/PixelArial11.woff2") format("woff2"),
    url("./assets/nft-assets/fonts/PixelArial11.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Argent Pixel";
  src: url("./assets/nft-assets/fonts/ArgentPixelCF-Regular.woff2")
      format("woff2"),
    url("./assets/nft-assets/fonts/ArgentPixelCF-Regular.woff") format("woff");
  font-weight: normal;
}

.content {
}
body {
  padding: 0;
}

// body {
//   background: url("./assets/nft-assets/background.gif");
// }

.survey {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
}

.error {
  color: #f00;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Chicago";
  font-size: 18px;
}

.show-connect-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  text-align: center;
}

.poolsuite-connect-component {
  background: #fff url("./assets/nft-assets/connect-bg.gif") !important;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2) !important;
  width: 540px;
  height: 290px;
  padding-top: 38px;
  position: relative;

  * {
    box-sizing: border-box;
    cursor: url("./assets/nft-assets/cursor.svg"), auto !important;
    font-smooth: never;
    -webkit-font-smoothing: none;
    -moz-osx-font-smoothing: none;
    -webkit-font-smoothing: antialiased;
  }

  .connect-component-close {
    background: url("./assets/nft-assets/perks-close.png") no-repeat center
      center !important;
    width: 20px;
    height: 20px;
    border: none;
    padding: 0;
    position: absolute;
    top: 10px;
    left: 10px;
    span {
      display: none;
    }
  }

  button {
    background: url("./assets/nft-assets/connect-blank-short.png") !important;
    width: 150px;
    height: 40px;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    display: block;
    &.metamask-icon {
      width: 19px;
      height: 16px;
      background: url("./assets/nft-assets/connect-metaMask.png") no-repeat;
    }
    &.walletconnect-icon {
      width: 21px;
      height: 13px;
      background: url("./assets/nft-assets/connect-walletConnect.png") no-repeat;
    }
    &.walletlink-icon {
      width: 16px;
      height: 16px;
      background: url("./assets/nft-assets/connect-walletLink.png") no-repeat;
      background-size: 16px auto;
    }
  }

  button,
  button *,
  .cursor {
    cursor: url("./assets/nft-assets/click.svg"), auto !important;
  }

  button:not(.button-new),
  button:not(.button-new) span {
    font-family: "Chicago", sans-serif;
    font-size: 16px !important;
  }

  button:not(.button-new):active {
    background: #000;
    color: #fff;
  }
  button {
    pointer-events: all;
  }

  .lines {
    height: 10px;
    span {
      width: 100%;
      display: block;
      background: #000;
      height: 1px;
      + span {
        margin-top: 2px;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    margin: 0.4em 0 0.6em 0;
    font-family: "Argent Pixel";
  }

  p {
    line-height: 1.8;
    font-size: 8px;
    padding: 2em 4em;
    text-align: center;
    font-family: Arial, sans-serif;
    font-weight: normal;
  }

  .login-button {
    position: relative;
  }

  .login-button > span {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000 !important;
  }

  .button-login-inner {
    display: flex;
    position: static;
    span {
      position: static;
    }
    img {
      max-height: 16px;
      width: auto;
      margin-left: 5px;
    }
  }

  .button-wrapper {
    margin: 0em 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      padding: 0;
      border: none;
      border-radius: 0;
      margin: 0;
      img {
        display: block;
      }
      + button {
        margin-left: 1em;
      }
    }
  }
  @media (max-width: 600px) {
    width: 252px;
    height: 374px;
    h2 {
      font-size: 16px;
      margin: 0.7em 0 1.2em 0;
    }
    p {
      padding-left: 14px;
      padding-right: 14px;
      padding-bottom: 0;
      br {
        display: none;
      }
    }
    .button-wrapper {
      flex-direction: column;
      margin-top: 1em;
      button + button {
        margin: 0.5em 0 0 0;
      }
    }
  }
}
</style>
